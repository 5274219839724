import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Icon, Switch } from "@material-ui/core";
import Aux from "../../global/AuxComp";
import GoogleAddress from "../../global/google-address";
import ConfirmAction from "../../global/confirm-action";
import CONSTANTS from "../../../constants";
import { Circle, Map } from "google-maps-react";
class ManageLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddNewLocation : false,
            selectedJob : {} ,
            isEditLocation : null,
            jobLocations: [],
            isOpenConfirm: null,
            currentJobLocation: {},
            isArchiveConfirm: false,
            isShowMap: false
        }
    }

    componentDidMount(){
        this.fetchJobLocation();
    }

    fetchJobLocation = async() => {
        let {job, tree_level, ref_id} = this.props;
        store.dispatch(setLoader(true))
        try{
            if(tree_level === 1) {
                this.setState({jobLocations : job.JobLocations })
            }
            else{
                let body = {
                    job_id: job.job_id,
                    tree_level,
                    ref_id
                }
                let resp = await ApiEndpoints.Jobs.fetchJobLocation(body);
                this.setState({jobLocations : resp.data.data })
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeLocation = (value, type) =>{
        let { currentJobLocation } = this.state;
        if(type === "location") currentJobLocation = { ...currentJobLocation, ...value};
        else currentJobLocation[type] = value;
        currentJobLocation.location_type = "FIELD";
        // currentJobLocation.location_title = " ";
        this.setState({currentJobLocation })
      }

      onChangeEditLocation = (value, type) =>{
        let { isEditLocation } = this.state;
        if(type === "location") isEditLocation = { ...isEditLocation, ...value};
        else isEditLocation[type] = value;
        this.setState({isEditLocation })
      }

      addJobLocation = async (i) =>{
        let { currentJobLocation } = this.state;
        let { tree_level, ref_id, job } = this.props;
        store.dispatch(setLoader(true))
        try{
            var locationBody = {
              job_id : job.job_id,
              tree_level, ref_id, 
              ...currentJobLocation,
              location_title : currentJobLocation.location_title || "-" 
            }
          let resp = await ApiEndpoints.Jobs.addLocation(locationBody);
          this.setState({isAddNewLocation : false, isOpenLocationModal : false, currentJobLocation : {}});
          if(tree_level === 1){
              job.JobLocations.push(resp.data.data);
              await this.props.onRefresh(job);
          }
          await this.fetchJobLocation();
          store.dispatch(setSuccess({show: true, message: "Location has been mapped to Project Successfully!"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

      deletedLocation = async (job_location_id) =>{
        let {job, tree_level} = this.props;
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.jobLocationDelete({job_location_id});
            job.JobLocations = job.JobLocations.filter(loc => loc.job_location_id !== job_location_id);
            if(tree_level === 1) this.props.onRefresh(job);
            await this.fetchJobLocation();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    editLocation = async (i) =>{
        let { isEditLocation } = this.state;
        let {job, tree_level} = this.props;
        store.dispatch(setLoader(true))
        try{
          let resp = await ApiEndpoints.Jobs.jobLocationEdit(isEditLocation);
          if(tree_level === 1){
            job.JobLocations = job.JobLocations.map(loc => {
                if(loc.job_location_id === isEditLocation.job_location_id) loc = resp.data.data;
                return loc;
            });
            await this.props.onRefresh(job);
          }
          await this.fetchJobLocation();
          this.setState({ isEditLocation : false})
          store.dispatch(setSuccess({show: true, message: "Location has been updated Successfully!"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    locationArchive = async (data) => {
        let {location, status, message} = data;
        let {jobLocations} = this.state;
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_location_id : location.job_location_id,
                archive_status : CONSTANTS.ARCHIVE_STATUS[status],
                archive_message : message || ""
            };
            var resp = await ApiEndpoints.Jobs.jobLocationEdit(body);
            jobLocations = jobLocations.map(loc => {
                if(loc.job_location_id === location.job_location_id) return resp.data.data;
                return loc;
            })
            this.setState({jobLocations, isArchiveConfirm : false});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }
    
    toggleLocationSourcing = async (location) => {
        let {job_location_id, sourcing_status} = location;
        let {jobLocations} = this.state;
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_location_id,
                sourcing_status: sourcing_status ? 0 : 1
            };
            var resp = await ApiEndpoints.Jobs.jobLocationEdit(body);
            jobLocations = jobLocations.map(loc => {
                if(loc.job_location_id === location.job_location_id) return resp.data.data;
                return loc;
            })
            this.setState({jobLocations, isArchiveConfirm : false});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    render() {
        let {job, tree_level} = this.props;
        let { isAddNewLocation, isEditLocation , isOpenAddSubLoc, jobLocations, isArchiveConfirm} = this.state;
        const coords = {lat: 22.8010508, lng: 81.7629981}

        return (
            <Aux>
                <Button className="buttonred mb-3" size="sm" onClick={() => this.setState({isAddNewLocation : true})}>Add {tree_level !== 1 && "Sub-"}Location</Button>{' '}
                <Icon className={"float-right"} style={{cursor: "pointer"}} onClick={() => this.setState({isShowMap: !this.state.isShowMap})}>map</Icon>
                {this.state.isShowMap &&
                <div style={{height: 320}} className="text-center">
                    <Map initialCenter={coords}  google={window.google} style={{width: "60%", height: 300, left: "20%"}} zoom={3.75} >
                        {jobLocations.map((loc, i) => 
                            <Circle
                                key={loc.job_location_id}
                                radius={loc.range * 1000}
                                center={{lat: parseFloat(loc.lat), lng: parseFloat(loc.long)}}
                                strokeColor='transparent'
                                strokeOpacity={0}
                                strokeWeight={5}
                                fillColor={loc.archive_status === 2 ? '#FF0000' : loc.archive_status === 1 ? "#ffa902" : "#019901"}
                                fillOpacity={0.5}
                            />
                        )}
                    </Map>
                </div>
                }
                <form>
                    <Row style={{fontSize: "14px"}}>
                        <Col>
                            <Row className="font-weight-bold">
                                <Col xs={tree_level === 1 ? 5 : 7}>Location</Col>
                                <Col xs={2}>Range</Col>
                                {tree_level === 1 && <Col xs={2}>Status</Col>}
                                <Col xs={1}>Sourcing?</Col>
                                <Col xs={2} className="text-center">Action</Col>
                            </Row>

                            { !!isAddNewLocation &&
                            <Row className="py-3">
                                <Col xs={tree_level === 1 ? 6 : 8} style={{ marginBottom: "10px"}}><GoogleAddress onPlaceChange={(loc) => this.onChangeLocation(loc, "location")} /></Col>
                                <Col xs={2}><InputFeild placeholder="Range(Km)" onChange={(e) => this.onChangeLocation(e.target.value, "range")} /></Col>
                                {tree_level === 1 && <Col xs={2}></Col>}
                                <Col xs={2}> 
                                    <div style={{marginTop: "7px"}}>
                                        <Icon onClick={this.addJobLocation} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                        <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isAddNewLocation : false})}>close</Icon>
                                    </div>
                                </Col>
                            </Row>
                            }

                            {jobLocations.map((location, i)=> 
                            isEditLocation && isEditLocation.job_location_id === location.job_location_id ?
                            <Row>
                                <Col xs={tree_level === 1 ? 6 : 8}><GoogleAddress onPlaceChange={(loc) => this.onChangeEditLocation(loc, "location")} /></Col>
                                <Col xs={2}><InputFeild required onChange={(e) => this.onChangeEditLocation(e.target.value, "range")} value={isEditLocation.range} /></Col>
                                {tree_level === 1 && <Col xs={2}></Col>}
                                <Col xs={2}> 
                                        <Icon className="mr-3" onClick={this.editLocation} style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                        <Icon  onClick={() => this.setState({isEditLocation : false})} style={{cursor: "pointer", fontSize: "16px"}}>close</Icon>
                                </Col>
                            </Row>
                            :
                            <Row key={i} className="align-items-center mt-2 py-1 bg-light shadow-sm">
                                <Col xs={tree_level === 1 ? 5 : 7}>
                                    {tree_level === 1? 
                                    <>
                                    <span className="d-block small font-weight-bold">{location.location_title}</span>
                                    <span>{location.city+", "+location.state} { location.zip ? " | "+location.zip : "" }</span>
                                    </>
                                    : 
                                    location.address
                                    }
                                </Col>
                                <Col xs={2}>{location.range}</Col>
                                {tree_level === 1 && 
                                <Col xs={2}>
                                    <div className="switch-toggle">
                                        {
                                            Object.keys(CONSTANTS.ARCHIVE_STATUS).map((key, i) => 
                                                <div key={i} className={location.archive_status === CONSTANTS.ARCHIVE_STATUS[key] ? "active" : ""} onClick={() => this.setState({isArchiveConfirm: {location, status: key}})}>{key}</div>
                                            )
                                        }
                                    </div>
                                    { location.archive_message && <Icon style={{fontSize: 12}} className="ml-1" title={location.archive_message}>message</Icon>}
                                </Col>}
                                <Col xs={1}>
                                        <Switch size="small" checked={!!location.sourcing_status} onChange={o => this.toggleLocationSourcing(location)} />
                                </Col>
                                <Col xs={2}> 
                                        {tree_level === 1 && <Icon style={{cursor: "pointer", fontSize : 16}} className="mr-2" onClick={() => this.setState({isOpenAddSubLoc: location.job_location_id === isOpenAddSubLoc? false : location.job_location_id})} >add_location_alt</Icon>}
                                        <Icon className="mr-3" onClick={() => this.setState({isEditLocation : location})} style={{cursor: "pointer", fontSize: "16px"}}>edit</Icon> 
                                        <Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedLocation(location.job_location_id)}) } style={{cursor: "pointer", fontSize: "16px"}}>delete</Icon>
                                </Col>
                                { isArchiveConfirm && isArchiveConfirm.location.job_location_id === location.job_location_id && <Col xs={12}>
                                    <Row className="justify-content-end m-2 p-2 border">
                                        <Col xs={6}>
                                            {isArchiveConfirm.status === "HOLD" && <InputFeild className="mb-3 p-0" style={{fontSize: 12}} placeholder={"Enter the message"} value={isArchiveConfirm.message || ""} onChange={(e) => {isArchiveConfirm.message = e.target.value; this.setState({isArchiveConfirm})}} />}
                                            <div>
                                                <b>Are You sure to {isArchiveConfirm.status}?</b>
                                                <div className="float-right">
                                                <Icon className="mr-3" onClick={() => this.locationArchive(isArchiveConfirm)} style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                                <Icon  onClick={() => this.setState({isArchiveConfirm : false})} style={{cursor: "pointer", fontSize: "16px"}}>close</Icon>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>}
                                <Col xs={12}>
                                    <Collapse isOpen={isOpenAddSubLoc === location.job_location_id}>
                                                <Card className="my-3">
                                                    <CardBody>
                                                        {tree_level === 1 && isOpenAddSubLoc === location.job_location_id && <ManageLocation tree_level={2} ref_id={isOpenAddSubLoc} job={this.props.job} />}
                                                    </CardBody>
                                                </Card>
                                    </Collapse>
                                </Col>

                            </Row>
                            )
                        }

                            </Col>
                    </Row>
                </form>
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

            </Aux>
            
            
        );
    }
}
export default ManageLocation;
