
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Progress } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css";
import {uploadFile, downloadApiFile} from "../../../actions";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import DatePicker from "react-datepicker";
import ConfirmAction from "../../global/confirm-action";
import TaskSchedule from "./task_schedule";
import TaskInstructions from "./task_instructions";
import JobPayment from "./job_payment";
import RichTextEditor from "../../global/form/Richtext";
import { TrackEvent } from "../../../actions/ga"
import TaskData from "../tasks/task_data";
import { AppBar, Box, Chip, Grid, makeStyles, Menu, MenuItem, Switch, Tab, Tabs, Typography } from "@material-ui/core";
import InputFeild from "../../global/form/input";
import JobTaskConfig from "./job_task_config";
import { AccessTime, Check, Close, TimerOff, Receipt } from "@material-ui/icons";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

let moment = require("moment");
 
class JobTaskDetail extends Component {
    constructor(props) {
      super(props);
      this.state = {
        taskList: [],
        selectedTask: {},
        isOpenVerifyTaskModal : false,
        isOpenUploadTaskResponseModal : false,
        isOpenTaskCommentsModal : false,
        verifyTaskResponseLoader : false,
        uploadTaskLoader : false,       
        taskCounts:{},
        isOpenConfirm : false,
        isOpenTaskTargetUpload : false,
        confirmMessage: null,
        isOpenDownload: false,
        isOpenGSheetVerification: false,
        isOpenPaymentGenerate: false,
        isOpenPaymentModal : false,
        isOpenTaskCustomerDetails: false,
        editDescription: null,
        editConsumerInstruction: null,
        isShowTaskData: false,
        isOpenTaskMenu: false,
        isOpenTaskConfig: false,
        rejectedReason: [],
        graphData: {},
        isShowExpired: false,
            isCreateNewClaim : false
        }
       }

    async componentDidMount() {
        this.fetchJobTasks();
       
    }

    componentDidUpdate(prevProps) {
        if (this.state.selectedTask.job_task_id && prevProps.selectedTaskCycle.refresh && !this.props.selectedTaskCycle.refresh) {
            this.selectTask(this.state.selectedTask);
        }
    }


    fetchJobTasks = async () => {
        let selectedJob = this.props.jobData;
        var body = { job_id: selectedJob.job_id }
        store.dispatch(setLoader(true))
        try {
            let taskResp = await ApiEndpoints.JobTask.fetch(body); 
            let taskList = taskResp.data.data;
            if (taskList.length) this.selectTask(taskList[0]);
            this.setState({ taskList })
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false))
    }

    onEditTaskDetail = (task) => {
        let { taskList } = this.state;
        taskList = taskList.map(obj => {
            if (obj.job_task_id === task.job_task_id) obj = { ...obj, ...task };
            return obj;
        })
        this.setState({ taskList })
    }


    selectTask = async (task) => {
        await this.setState({ selectedTask: task })
        if (this.props.selectedTaskCycle && this.props.selectedTaskCycle.type) {
            this.fetchTaskCount();
            this.fetchTaskGraphData()
            this.rejectionReason()
        }
    }

    fetchTaskCount = async () => {
        store.dispatch(setLoader(true))
        let { selectedTaskCycle } = this.props;
        let { selectedTask, isShowExpired } = this.state;
        var task = selectedTask;
        let cycleDate = {}
        try {
            if (selectedTaskCycle.start_date) cycleDate.start_date = selectedTaskCycle.start_date;
            if (selectedTaskCycle.end_date) cycleDate.end_date = selectedTaskCycle.end_date;
            // if(isShowExpired) cycleDate.archive_status = [0, 1];
            cycleDate.archive_status = [0, 1];
            if (task.formData.response_data_type === "GROUP_COUNT") {
                let response = await ApiEndpoints.Jobs.getGroupFormResponse({ form_id: task.form_id, ...cycleDate });
                let responseData = response.data.data.reduce((prev, curr) => { prev[curr.status] = curr; return prev; }, {})
                this.setState({
                    taskCounts: {
                        VERIFIED: responseData["VERIFIED"] ? responseData["VERIFIED"].response_counts : 0,
                        PENDING: responseData["PENDING"] ? responseData["PENDING"].response_counts : 0,
                        REJECTED: responseData["REJECTED"] ? responseData["REJECTED"].response_counts : 0,
                    }, 
                });
                
            }
            else {
                let resp = await ApiEndpoints.Jobs.getFormCount({ form_id: task.form_id, ...cycleDate });
                this.setState({ taskCounts: resp.data.data });
            }
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false))
    }

    editTaskCustomerDetails = async () => {
        store.dispatch(setLoader(true))
        let { selectedTask } = this.state;
        var task = selectedTask;
        let { isOpenTaskCustomerDetails, editConsumerInstruction, editDescription } = this.state;
        try {
            var body = { job_task_id: task.job_task_id }
            if (isOpenTaskCustomerDetails === "description") body.description = editDescription;
            else body.consumer_instruction = editConsumerInstruction;
            await ApiEndpoints.JobTask.edit(body);
            this.setState({ isOpenTaskCustomerDetails: false, editConsumerInstruction: null, editDescription: null });
            this.props.onEditTaskDetail && this.props.onEditTaskDetail(body);
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false))
    }

    // updateHoldStatus = async (holdMessage) => {
    //     store.dispatch(setLoader(true))
    //     var task = this.props.taskData;
    //     try{
    //         var body = { job_task_id: task.job_task_id, description: task.description || null, hold_message: holdMessage || null }
    //         await ApiEndpoints.JobTask.edit(body);
    //         this.props.onEditTaskDetail && this.props.onEditTaskDetail(body);
    //         this.setState({ isAddHoldMessage: false })
    //     }
    //     catch(e){
    //         store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
    //     }
    //     store.dispatch(setLoader(false))
    // }
    
    verifyTaskResponseByCsv = async (e) => {
        TrackEvent("TASK_DATA", "TASK_VERIFICATION_EXCEL_UPLOAD", "TASK_VERIFICATION");
        e.preventDefault();
        this.setState({ verifyTaskResponseLoader: true })
        let { isOpenVerifyTaskModal } = this.state;
        let { selectedTaskCycle, jobData } = this.props;
        if (["PREV_CYCLE", "CURR_CYCLE"].indexOf(selectedTaskCycle.type) === -1) {
            this.setState({ verifyTaskResponseLoader: false })
            store.dispatch(setError({ show: true, message: "Please select Task Cycle" }));
            return;
        }
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))

        try {
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key: fileResp.file_key,
                job_id: jobData.job_id,
                job_task_id: isOpenVerifyTaskModal,
                ...selectedTaskCycle
            }
            let resp = await ApiEndpoints.JobTask.verifyResponseByCsv(body)
            this.setState({ isOpenVerifyTaskModal: false });
            store.dispatch(setSuccess({ show: true, message: resp.data.message }))
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        this.setState({ verifyTaskResponseLoader: false })
        store.dispatch(setLoader(false))

    }
    addTaskResponseByCsv = async (e) => {
        e.preventDefault();
        this.setState({ verifyTaskResponseLoader: true })
        let { isOpenUploadTaskResponseModal } = this.state;
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))

        try {
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key: fileResp.file_key,
                form_id: isOpenUploadTaskResponseModal
            }
            let resp = await ApiEndpoints.JobTask.addResponseByCsv(body)
            this.setState({ isOpenUploadTaskResponseModal: false });
            store.dispatch(setSuccess({ show: true, message: resp.data.message }))
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        this.setState({ verifyTaskResponseLoader: false })
        store.dispatch(setLoader(false))
    }
    uploadTaskTarget = async (e) => {
        e.preventDefault();
        this.setState({ verifyTaskResponseLoader: true })
        let { isOpenTaskTargetUpload } = this.state;
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))

        try {
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key: fileResp.file_key,
                job_id: this.props.jobData.job_id,
                job_task_id: isOpenTaskTargetUpload.job_task_id,
                start_date: isOpenTaskTargetUpload.start_date,
                end_date: isOpenTaskTargetUpload.end_date
            }
            let resp = await ApiEndpoints.JobTask.uploadTargetCsv(body);
            
            this.setState({ isOpenTaskTargetUpload: false });
            store.dispatch(setSuccess({ show: true, message: resp.data.message }))
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        this.setState({ verifyTaskResponseLoader: false })
        store.dispatch(setLoader(false))
    }
    addGroupedTaskResponseByCsv = async (e) => {
        TrackEvent("TASK_DATA", "TASK_DATA_GROUP_UPLOAD", "TASK_DATA_UPLOAD");
        e.preventDefault();
        this.setState({ verifyTaskResponseLoader: true })
        let { isOpenUploadGroupedTaskResponseModal } = this.state;
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))

        try {
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key: fileResp.file_key,
                form_id: isOpenUploadGroupedTaskResponseModal
            }
            let resp = await ApiEndpoints.JobTask.addGroupedResponseByCsv(body)
            this.setState({ isOpenUploadGroupedTaskResponseModal: false });
            store.dispatch(setSuccess({ show: true, message: resp.data.data.length + " Records created" }))
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        this.setState({ verifyTaskResponseLoader: false })
        store.dispatch(setLoader(false))

    }


    verifyTaskCommentByCsv = async (e) => {
        e.preventDefault();
        this.setState({ verifyTaskResponseLoader: true })
        let { isOpenTaskCommentsModal } = this.state;
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))
        try {
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key: fileResp.file_key,
                form_id: isOpenTaskCommentsModal
            }
            let resp = await ApiEndpoints.JobTask.updateTaskResponseCommentByCsv(body)
            this.setState({ isOpenTaskCommentsModal: false });
            store.dispatch(setSuccess({ show: true, message: resp.data.message }))
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        this.setState({ verifyTaskResponseLoader: false })
        store.dispatch(setLoader(false))

    }


    downloadTask = async (opts = {}) => {
        TrackEvent("TASK_DATA", "TASK_DATA_DOWNLOAD", "TASK_DATA_DOWNLOAD");
        store.dispatch(setLoader(true));
        let { job_task_id, form_id, formData } = this.state.selectedTask;
        let { status, start_date, end_date } = opts;
        try {
            var body = {
                job_task_id, status, form_id
            }
            if (start_date) body.start_date = moment(start_date).startOf("day").toDate();
            if (end_date) body.end_date = moment(end_date).endOf("day").toDate();
            // console.log(formData)
            if (formData.response_data_type === "GROUP_COUNT") {
                var resp1 = await ApiEndpoints.JobTask.downloadFormGroupedResponse(body);
                downloadApiFile(resp1, "Task Response.csv")
            }
            else {
                var resp2 = await ApiEndpoints.JobTask.downloadFormResponse(body);
                downloadApiFile(resp2, "Task Response.csv")
            }
        }
        catch (e) {
            console.log(e.response)
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false))
    }
    
    

    paymentCalculate = async (e, data) => {
        TrackEvent("PAYMENT", "PAYMENT_CALCULATION_GENERATE", "PAYMENT_CALCULATION");
        e.preventDefault();
        let { form_id, start_date, end_date } = data;
        if (!(start_date && end_date)) return store.dispatch(setError({ show: true, message: "Mandatory: Select payment cycle dates first" }));
        store.dispatch(setLoader(true))
        try {
            let body = { 
                form_id,
                start_date, 
                end_date,
                // is_include_terminated : e.target.is_include_terminated.checked
            } 
            await ApiEndpoints.JobTaskPayment.calculate(body);
            this.setState({ isOpenPaymentGenerate: false })
            store.dispatch(setSuccess({ show: true, message: "Request to Payment Calculation has submitted successfully. It will take few moments to reflect" }))
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false))
    }

    syncTaskGSheetVerification = async (data) => {
        TrackEvent("TASK_DATA", "TASK_DATA_VERIFICATION_GOOGLE_SYNC", "TASK_DATA_VERIFICATION");
        let { job_task_id, start_date, end_date } = data;
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_task_id, 
                startDate: moment(start_date).startOf("day").toDate(), 
                endDate: moment(end_date).endOf("day").toDate()
            };
            var resp = await ApiEndpoints.JobTask.syncVerificationGSheet(body);
            data.responseMessage = resp.data.data;
            this.setState({ isOpenGSheetVerification: data });
        }
        catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

    rejectionReason = async () => {
        store.dispatch(setLoader(true))
        let { rejectedReason } = this.state;
        let { selectedTaskCycle } = this.props;
        let { selectedTask, isShowExpired } = this.state;
        var task = selectedTask;
        try {
            var body = {
                form_id: task.form_id,
            }
            if (selectedTaskCycle.start_date) body.start_date = selectedTaskCycle.start_date;
            if (selectedTaskCycle.end_date) body.end_date = selectedTaskCycle.end_date;
            // if(isShowExpired) body.archive_status = [0, 1];
            body.archive_status = [0, 1];
           let response = await ApiEndpoints.JobTask.fetchRejectionReason(body)
           rejectedReason = response.data.data;
            this.setState({ rejectedReason })
        } catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }
  
    fetchTaskGraphData = async () => {
        store.dispatch(setLoader(true))
        let { selectedTaskCycle } = this.props;
        let { selectedTask, isShowExpired } = this.state;
        var task = selectedTask;
        try {
            var body = { form_id: task.form_id }
            if (selectedTaskCycle.start_date) body.start_date = selectedTaskCycle.start_date;
            if (selectedTaskCycle.end_date) body.end_date = selectedTaskCycle.end_date;
            // if(isShowExpired) body.archive_status = [0, 1];
            body.archive_status = [0, 1];
           let response = await ApiEndpoints.JobTask.fetchTaskCountGraphData(body)
           let graphData = {};
            const STATUS_TEXT = { 1: "Verified", 2: "Rejected", 0: "Pending" }
           Object.keys(response.data.data).forEach(status => {
                response.data.data[status].forEach(statusData => {
                    graphData[statusData.date] = graphData[statusData.date] || { date: statusData.date, Verified: 0, Rejected: 0, Pending: 0, Expired: 0 };
                    graphData[statusData.date][STATUS_TEXT[status]] = statusData.count;
                    graphData[statusData.date].Expired = parseInt(statusData.archived_count) + graphData[statusData.date].Expired;
                })
           });
           console.log(graphData);
           graphData = Object.values(graphData);
            graphData = graphData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
           this.setState({ graphData })
        } catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }))
        }
        store.dispatch(setLoader(false));
    }

    showExpiredData = async (status) => {
        await this.setState({ isShowExpired: status });
        let { selectedTask } = this.state;
        this.selectTask(selectedTask);
    }
  
    
    render() {
        let { taskList, selectedTask: taskData, isOpenVerifyTaskModal, isOpenUploadTaskResponseModal, isOpenTaskCommentsModal, verifyTaskResponseLoader, isOpenScheduleModal, taskCounts, isOpenPaymentGenerate, editDescription, editConsumerInstruction, isOpenTaskMenu, rejectedReason, graphData, isCreateNewClaim  } = this.state;
        let { isOpenUploadGroupedTaskResponseModal, isOpendownloadTask, isOpenTaskTargetUpload, isOpenGSheetVerification, isOpenPaymentModal, isOpenTaskCustomerDetails, isShowTaskData, isAddHoldMessage, isOpenTaskConfig } = this.state;
        let { jobData, selectedTaskCycle } = this.props;
        var userRole = store.getState().user.UserRole;       
        var taskCountStyle = {
            PENDING: { background: "#e9e9e9", color: "#ccc", Icon: <AccessTime style={{ position: 'absolute', fontSize: "smaller" }} /> },
            VERIFIED: { background: "#00ff0010", color: "#090", Icon: <Check style={{ position: 'absolute', fontSize: "smaller" }} /> },
            REJECTED: { background: "#ff000010", color: "#f00", Icon: <Close style={{ position: 'absolute', fontSize: "smaller" }} /> },
            archived_count: { background: "#e9e9e9", color: "#ccc", Icon: <TimerOff style={{ position: 'absolute', fontSize: "smaller" }} /> }
        }

        return (
           <>
                <Row className="my-3 align-items-center">
                    <Col>
                        {taskList.map((task, index) =>
                            <strong className="d-inline-block mr-3 small" style={{ cursor: "pointer", borderBottom: task.job_task_id === taskData.job_task_id ? "1px solid" : "none" }} onClick={() => this.selectTask(task)}>{task.task_title}</strong>
                        )}
                    </Col>
                    {!!taskData.job_task_id ?
                    <Col className="text-right">
                        {/* <span style={{fontSize: 14}}>Include Expired?</span> <Switch size="small" checked={!!this.state.isShowExpired} onChange={(e) =>  this.showExpiredData(e.target.checked) } /> */}
                        {taskData.formData.input_by === "EXCEL" ? 
                            <>
                            {taskData.formData.response_data_type === "GROUP_COUNT" ?
                                        <Chip id={"add_task_response_" + taskData.job_task_id} onClick={() => { this.setState({ isOpenUploadGroupedTaskResponseModal: taskData.form_id }) }} type="button" size="sm" className="buttonred">Add Grouped Task Response</Chip>
                                :
                                        <Chip label="Add Task Response" id={"add_task_response_" + taskData.job_task_id} onClick={() => { this.setState({ isOpenUploadTaskResponseModal: taskData.form_id }) }} type="button" size="sm" className="buttonred" />
                            }
                            </>
                            :
                                <Button id={"verify_task_response_" + taskData.job_task_id} onClick={() => { this.setState({ isOpenVerifyTaskModal: taskData.job_task_id }) }} type="button" size="sm" className="mx-2 font-weight-bold" style={{ color: "#ffff", background: "rgb(255, 26, 26)", fontSize: 11, border: "none", borderRadius: "100px" }}>Verify Task Response</Button>
                        }

                        {taskData.formData.is_payment_automated && !!userRole.filter(role => [1, 3].indexOf(role.role_id) !== -1).length &&
                                <Button id={"generate_task_payment_" + taskData.job_task_id} onClick={() => this.setState({ isOpenPaymentGenerate: { form_id: taskData.form_id, ...(["PREV_CYCLE", "CURR_CYCLE"].indexOf(selectedTaskCycle.type) !== -1 ? { start_date: selectedTaskCycle.start_date, end_date: selectedTaskCycle.end_date } : {}) } })}
                                    size="sm" type="button" className="mr-2 font-weight-bold mx-auto" style={{ color: "#ffff", background: "rgb(255, 26, 26)", fontSize: 11, border: "none", borderRadius: "100px" }}>Generate Payments</Button>
                        }

                            <Icon onClick={(e) => this.setState({ isOpenTaskMenu: e.currentTarget })} className="mr-2 align-middle">more_vert</Icon>
                        
                            <Menu anchorEl={isOpenTaskMenu} open={!!isOpenTaskMenu} onClose={() => this.setState({ isOpenTaskMenu: false })} onClick={() => this.setState({ isOpenTaskMenu: false })}>
                                {taskData.gsheet_verification_config && <MenuItem onClick={() => { this.setState({ isOpenGSheetVerification: { ...taskData } }) }}>Pull GSheet Verification</MenuItem>}
                                <MenuItem onClick={() => { this.setState({ isOpenTaskCustomerDetails: "instruction" }) }}>Instructions Reminder</MenuItem>
                            {jobData.consumer_type === 2 && 
                                    <MenuItem onClick={() => { this.setState({ isOpenTaskCustomerDetails: "description" }) }}>Customer Description</MenuItem>
                            }
                                <MenuItem onClick={() => { this.setState({ isOpenTaskConfig: true }) }}>Task Config</MenuItem>
                            {taskData.formData.is_payment_automated && !!userRole.filter(role => [1, 3].indexOf(role.role_id) !== -1).length &&
                                    <MenuItem id="project_payment_structure_popup" onClick={() => { this.setState({ isOpenPaymentModal: true }) }}>Pay Structure</MenuItem>
                            }
                        </Menu>
                    </Col>
                        :
                        <Col>
                            <Button onClick={() => this.setState({ isCreateNewClaim : true })}  size="xs" type="button" className="buttonred float-right ml-2" style={{ color: "#ffff", background: "rgb(255, 26, 26)", fontSize: 11, border: "none", borderRadius: "100px" }}>Create Claim Form</Button>
                        </Col>
                    }
                </Row>
                    
                <Row>
                {["PENDING", "VERIFIED", "REJECTED", "archived_count"].map((status, statusKey) => 
                        <Col lg={2} md={4} xs={6} style={{ minWidth: "18%" }} className="pr-2" key={statusKey}>
                            <Row noGutters className="align-items-center border border-light shadow-sm" style={{ cursor: "pointer", borderRadius: 8 }} onClick={() => this.setState({ isShowTaskData: { task: taskData, status } })}>
                                {/* <Icon className='MuiGrid-spacing-xs-2 mx-auto' id={"download_pending_task_"+taskData.job_task_id} onClick={() => this.setState({isOpendownloadTask : {status: statusKey }})} style={{cursor: "pointer", fontSize: 13, position: "absolute", color: taskCountStyle[status].color,top:3 }}>get_app</Icon>      */}
                            <Col xs={"auto"} className="" >
                                    <div className="p-1 my-3 mx-2" style={{ borderRadius: 10, ...taskCountStyle[status] }}>
                                        <Icon className="ml-1 d-flex align-items-center" style={{ color: taskCountStyle[status].color }}>{taskCountStyle[status].Icon}</Icon>
                                </div>
                            </Col>
                            <Col className="font-weight-bold">
                                    <span className="d-block" style={{ fontSize: 12 }}>{status === 'archived_count' ? "Expired" : status[0] + (status.slice(1).toLowerCase())}</span>
                                <span>{taskCounts[status] || 0}</span>
                                    {status === "PENDING" && !!taskCounts["PENDING_PARTIAL"] && <span className="float-right mr-2" style={{ fontSize: 14 }}><Icon className="align-middle" style={{ color: "#ffa707", fontSize: 12 }}>warning</Icon> {taskCounts["PENDING_PARTIAL"] || 0}</span>}
                            </Col>
                        </Row>
                    </Col>  
                )}        
                </Row>
                    
                                                
                        {/* <Grid item lg={5} md={6} xs={12} className={"shadow-sm ml-3 p-1"}>
                                    <Grid container className="p-2" alignItems="center">
                                        <Row className="align-items-center ml-0">
                                            <Col lg={4} md={6} xs={10} className="p-0 d-flex" style={{whiteSpace:"normal"}}>
                                            <div className="align-items-center">
                                            <div className="text-muted small font-weight-bold mr-3 d-flex" >
                                            <div className=" px-1 my-2 mx-1 d-flex align-items-center" style={{borderRadius: 8,background:"#00ff0010"}}><Icon style={{color:"#090"}}>payment</Icon></div>
                                            <div style={{fontSize:"11px",color:"#212529"}}>
                                                <span>Payement Completed</span> 
                                                <br/><strong >20</strong>
                                            </div>
                                            </div>                                       
                                            </div>
                                            <div className=" align-items-center">
                                        <div className="text-muted small font-weight-bold d-flex">
                                            <div className=" px-1 my-2 mx-2 d-flex align-items-center" style={{borderRadius: 8,background:"#ff000010"}}><Icon style={{color:"#f00"}}>payment</Icon></div>
                                           <div style={{fontSize:"11px",color:"#212529"}}>
                                                <span>Payment Rejected</span> 
                                                <br/><strong >20</strong>
                                            </div>
                                        </div>                                       
                                    </div>
                                            </Col>
                                        </Row>
                                    </Grid>
                        </Grid> */}
                <Row className="mt-3">
                    <Col xs={12} lg={8} >
                        <div className="border shadow-sm align-items-center p-2" style={{ borderRadius: 8, height: 250 }}>
                            <ResponsiveContainer className="mt-2" width="100%" height="90%">
                                <LineChart width={50} height={10} data={graphData} >
                                    <CartesianGrid strokeDasharray="5 5" />
                                    <XAxis dataKey="date" tickFormatter={date => moment(date).format("Do MMM'YY")} />
                                    <YAxis />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Line type="monotone" dataKey="Pending" stroke="#6c757d" />
                                    <Line type="monotone" dataKey="Verified" stroke="#008000" />
                                    <Line type="monotone" dataKey="Rejected" stroke="#ff0000" />
                                    <Line type="monotone" dataKey="Expired" stroke="#6c757d" strokeDasharray="5 5" />

                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="border shadow-sm align-items-center p-2" style={{ borderRadius: 8, background: "rgba(251, 205, 198, 0.31)", height: 250, overflowY: "scroll" }}>
                            <strong className="mx-3" style={{ fontSize: 14 }}>Rejection Reason</strong>
                            {rejectedReason.length ? rejectedReason.map((rejected) =>
                                <Row className="mx-0" title={rejected.status_comment} >
                                    <Col xs={12} className="my-2">
                                        <Row className="align-items-between">
                                            <Col xs={10} style={{}}><span className="small"  >{rejected.status_comment || ""}</span></Col>
                                            {/* <Col xs={10}style={{whiteSpace:"nowrap", textOverflow: "ellipsis", overflow: "hidden"}}><span className=""  >{rejected.status_comment || ""}</span></Col> */}
                                            <Col xs={2}><strong className="text-right">{rejected.counts || 0}</strong></Col> 
                                        </Row>
                                        <Progress style={{ height: '3px' }} value={rejected.counts} max={55} color="danger" />
                                    </Col>                            
                                </Row>
                            ) 
                            :  
                            <em className="d-block m-3">No Data</em>
                            }
                        </div>
                    </Col>       
                </Row>

                <Modal centered size="lg" isOpen={!!isAddHoldMessage} toggle={() => { this.setState({ isAddHoldMessage: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isAddHoldMessage: false }) }}>Task Hold Message <br></br></ModalHeader>
                    <ModalBody>
                        <form onSubmit={(e) => { e.preventDefault(); this.updateHoldStatus(isAddHoldMessage.message) }}>
                            <InputFeild className="mt-3" required placeholder="Enter hold message" onChange={(e) => { isAddHoldMessage.message = e.target.value; this.setState({ isAddHoldMessage }) }} />
                            <div className="text-right mt-3">
                                <Button className="buttonred" >Save</Button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>

                <Modal size="xl" isOpen={isShowTaskData} toggle={()=> {this.setState({isShowTaskData : false})}} >
                    <ModalHeader toggle={()=> {this.setState({isShowTaskData : false})}}>Task Data <br></br></ModalHeader>
                    <ModalBody>
                        {!!isShowTaskData && <TaskData jobData={jobData} jobTask={isShowTaskData.task} selectedStatus={isShowTaskData.status} selectedTaskCycle={selectedTaskCycle} isShowExpired={isShowTaskData.status === "archived_count"} />}
                    </ModalBody> 
                </Modal>
                
                
                <Modal size="lg" isOpen={isOpenPaymentModal} toggle={() => { this.setState({ isOpenPaymentModal: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isOpenPaymentModal: false }) }}>Payment <br></br></ModalHeader>
                    <ModalBody>
                        {!!isOpenPaymentModal && <JobPayment job={jobData} jobTask={taskData} />}
                    </ModalBody>
                </Modal>

                <Modal size="lg" isOpen={isOpenTaskConfig} toggle={() => { this.setState({ isOpenTaskConfig: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isOpenTaskConfig: false }) }}>Task Config <br></br></ModalHeader>
                    <ModalBody>
                        {!!isOpenTaskConfig && <JobTaskConfig job={jobData} jobTask={taskData} onClose={() => { this.setState({ isOpenTaskConfig: false }) }} />}
                    </ModalBody>
                </Modal>


                <Modal isOpen={!!isOpenPaymentGenerate} toggle={() => { this.setState({ isOpenPaymentGenerate: false }) }} >
                    <form onSubmit={(e) => this.paymentCalculate(e, isOpenPaymentGenerate)}>
                        <ModalHeader toggle={() => { this.setState({ isOpenPaymentGenerate: false }) }}>Generate Payment</ModalHeader>
                    <ModalBody className="p-3">
                            <h5 className="mb-3">Are you sure to request Payment Calculation for selected cycle: {isOpenPaymentGenerate.start_date ? moment(isOpenPaymentGenerate.start_date).format("Do MMM") : "__"} - {isOpenPaymentGenerate.end_date ? moment(isOpenPaymentGenerate.end_date).format("Do MMM") : "__"}?</h5>
                            <span style={{ fontSize: 12 }}>Select Start Date</span>
                        <DatePicker
                            selected={isOpenPaymentGenerate && isOpenPaymentGenerate.start_date}
                            onChange={(date) => {
                                isOpenPaymentGenerate.start_date = moment(date).startOf("day").toDate();
                                isOpenPaymentGenerate.end_date = moment(date).add(6, "days").endOf("day").toDate();
                                    this.setState({ isOpenPaymentGenerate });
                            }}
                            dateFormat='dd-MMM-yyyy'
                            selectsStart
                            showWeekNumbers
                            locale="en-gb"
                            autoComplete="false"
                            filterDate={(date) => new Date(date).getDay() === 1}
                            startDate={isOpenPaymentGenerate && isOpenPaymentGenerate.start_date}
                            endDate={isOpenPaymentGenerate && isOpenPaymentGenerate.end_date}
                        />
                        {/* <input type="checkbox" name="is_include_terminated" /> Is Include Terminated Partners ? */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="payment_generate_submit" size="sm" type="submit" color="danger" >Generate</Button>{' '}
                            <Button id="payment_generate_cancel" size="sm" type="button" color="secondary" onClick={() => { this.setState({ isOpenPaymentGenerate: false }) }}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                
                <Modal isOpen={!!isOpenTaskTargetUpload} toggle={() => { this.setState({ isOpenTaskTargetUpload: false }) }} >
                    <form onSubmit={this.uploadTaskTarget}>
                        <ModalHeader toggle={() => { this.setState({ isOpenTaskTargetUpload: false }) }}>Add Task Targets by CSV</ModalHeader>
                    <ModalBody className="p-5">
                        <input id="upload_task_target_input" required type="file" name="csvFile" />
                        <p>*upload excel file to add Task Targets for selected cycle</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="upload_target_submit" disabled={verifyTaskResponseLoader} type="submit" color="primary" >Upload Target</Button>{' '}
                            <Button id="upload_target_cancel" type="button" color="secondary" onClick={() => { this.setState({ isOpenTaskTargetUpload: false }) }}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={!!isOpenUploadTaskResponseModal} toggle={() => { this.setState({ isOpenUploadTaskResponseModal: false }) }} >
                    <form onSubmit={this.addTaskResponseByCsv}>
                        <ModalHeader toggle={() => { this.setState({ isOpenUploadTaskResponseModal: false }) }}>Add Task Response by CSV</ModalHeader>
                    <ModalBody className="p-5">
                        <input id="add_task_target_input" required type="file" name="csvFile" />
                        <p>*upload excel file to add Task Response</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="add_task_resp_submit" disabled={verifyTaskResponseLoader} type="submit" color="primary" >Upload Data</Button>{' '}
                            <Button id="add_task_resp_cancel" type="button" color="secondary" onClick={() => { this.setState({ isOpenUploadTaskResponseModal: false }) }}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={!!isOpenUploadGroupedTaskResponseModal} toggle={() => { this.setState({ isOpenUploadGroupedTaskResponseModal: false }) }} >
                    <form onSubmit={this.addGroupedTaskResponseByCsv}>
                        <ModalHeader toggle={() => { this.setState({ isOpenUploadGroupedTaskResponseModal: false }) }}>Add Task Response by CSV</ModalHeader>
                    <ModalBody className="p-5">
                        <input id="upload_grouped_task_resp_input" required type="file" name="csvFile" />
                        <p>*upload excel file to add Task Response grouped data</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="upload_grouped_task_resp_submit" disabled={verifyTaskResponseLoader} type="submit" color="primary" >Upload Data</Button>{' '}
                            <Button id="upload_grouped_task_resp_cancel" type="button" color="secondary" onClick={() => { this.setState({ isOpenUploadGroupedTaskResponseModal: false }) }}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={!!isOpenVerifyTaskModal} toggle={() => { this.setState({ isOpenVerifyTaskModal: false }) }} >
                    <form onSubmit={this.verifyTaskResponseByCsv}>
                        <ModalHeader toggle={() => { this.setState({ isOpenVerifyTaskModal: false }) }}>Verify Task Response by CSV</ModalHeader>
                    <ModalBody className="p-5">
                            <p>You are uploading Tasks file for cycle <em>{moment(selectedTaskCycle.start_date).utcOffset("+05:30").format("Do MMM")} - {moment(selectedTaskCycle.end_date).utcOffset("+05:30").format("Do MMM")}</em></p>
                        <input id="verify_task_resp_input" required type="file" name="csvFile" />
                            <p className="mt-2" style={{ fontSize: 12 }}>*You have to upload the file that you have downloaded and change the status</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="verify_task_resp_submit" disabled={verifyTaskResponseLoader} type="submit" color="primary" >Update Status</Button>{' '}
                            <Button id="verify_task_resp_cancel" type="button" color="secondary" onClick={() => { this.setState({ isOpenVerifyTaskModal: false }) }}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={isOpenTaskCommentsModal} toggle={() => { this.setState({ isOpenTaskCommentsModal: false }) }} >
                    <form onSubmit={this.verifyTaskCommentByCsv}>
                        <ModalHeader toggle={() => { this.setState({ isOpenTaskCommentsModal: false }) }}>Update Task Response comments by CSV</ModalHeader>
                    <ModalBody className="p-5">
                        <input id="add_task_resp_comment_input" required type="file" name="csvFile" />
                        <p>*You have to upload the file that you have downloaded and change the `Status Comment` column</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="add_task_resp_comment_submit" disabled={verifyTaskResponseLoader} type="submit" color="primary" >Update Status</Button>{' '}
                            <Button id="add_task_resp_comment_cancel" type="button" color="secondary" onClick={() => { this.setState({ isOpenTaskCommentsModal: false }) }}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={!!isOpendownloadTask} toggle={() => { this.setState({ isOpendownloadTask: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isOpendownloadTask: false }) }}>Download Task Data</ModalHeader>
                    <ModalBody className="pb-5 pl-5 pr-5 pb-3">
                    <Row className="border-top mt-4">
                            <Col>
                                <DatePicker
                                    selected={this.state.isOpendownloadTask ? this.state.isOpendownloadTask.start_date : null}
                                    onChange={date => { isOpendownloadTask.start_date = date; this.setState({ isOpendownloadTask }) }}
                                    className="mt-3"
                                    dateFormat='dd-MMM-yyyy'
                                    id="download_task_resp_input1"
                                    placeholderText="Start Date"
                                    autoComplete="false"
                                />
                            </Col>
                            <Col>
                                <DatePicker
                                    selected={this.state.isOpendownloadTask ? this.state.isOpendownloadTask.end_date : null}
                                    onChange={date => { isOpendownloadTask.end_date = date; this.setState({ isOpendownloadTask }) }}
                                    className="mt-3"
                                    dateFormat='dd-MMM-yyyy'
                                    id="download_task_resp_input2"
                                    placeholderText="End Date"
                                    autoComplete="false"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center mt-4">
                                <Button id="download_task_resp_submit" size="sm" onClick={() => this.downloadTask(isOpendownloadTask)} color="dark">Download</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal size="lg" isOpen={isOpenScheduleModal}  >
                    <ModalHeader toggle={() => { this.setState({ isOpenScheduleModal: false }) }}>Manage Scheduling</ModalHeader>
                    <ModalBody>
                        {!!isOpenScheduleModal && <TaskSchedule task={isOpenScheduleModal} onClose={() => this.setState({ isOpenScheduleModal: false })} />}
                    </ModalBody>
                </Modal>
                
                <Modal size="md" isOpen={isOpenTaskCustomerDetails} toggle={() => { this.setState({ isOpenTaskCustomerDetails: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isOpenTaskCustomerDetails: false }) }}>Manage Customer {isOpenTaskCustomerDetails === "description" ? "Description" : "Instruction"}</ModalHeader>
                    <ModalBody className="px-4">
                        {
                        isOpenTaskCustomerDetails === "description" ? 

                                <RichTextEditor placeholder="Enter Description to be visible to end customer" value={editDescription || taskData.description || ""} onChange={(value) => { this.setState({ editDescription: value }) }} />
                        :
                        <>
                            <span className="d-inline-block mb-2">*These instructions will be shown to partner as reminder before submit task and also in the onboarding feedback form</span>
                                    <RichTextEditor placeholder="Enter Instruction to be visible to end customer" value={editConsumerInstruction || taskData.consumer_instruction || ""} onChange={(value) => this.setState({ editConsumerInstruction: value })} />
                        </>
                        }

                        <Row className={"border-top mt-2"}> 
                            <Col className="text-right mt-2">
                                <Button size="sm" onClick={() => this.editTaskCustomerDetails()} color="dark">Save</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal isOpen={!!isOpenGSheetVerification} toggle={() => { this.setState({ isOpenGSheetVerification: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isOpenGSheetVerification: false }) }}>Sync verification with Google Sheet</ModalHeader>
                    <ModalBody className="px-4">
                        {isOpenGSheetVerification.responseMessage ? 
                        <div dangerouslySetInnerHTML={{ __html: isOpenGSheetVerification.responseMessage }}></div>
                        :
                        <>
                        <Row>
                            <Col>
                                <DatePicker
                                    selected={isOpenGSheetVerification ? isOpenGSheetVerification.start_date : null}
                                            onChange={date => { isOpenGSheetVerification.start_date = date; this.setState({ isOpenGSheetVerification }) }}
                                    className="mt-3"
                                    dateFormat='dd-MMM-yyyy'
                                    placeholderText="Start Date"
                                    autoComplete="false"
                                />
                            </Col>
                            <Col>
                                <DatePicker
                                    selected={isOpenGSheetVerification ? isOpenGSheetVerification.end_date : null}
                                            onChange={date => { isOpenGSheetVerification.end_date = date; this.setState({ isOpenGSheetVerification }) }}
                                    className="mt-3"
                                    dateFormat='dd-MMM-yyyy'
                                    placeholderText="End Date"
                                    autoComplete="false"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center mt-4">
                                <Button size="sm" onClick={() => this.syncTaskGSheetVerification(isOpenGSheetVerification)} color="dark">Sync Verification</Button>
                            </Col>
                        </Row>
                        </>}
                    </ModalBody>
                </Modal>
                
              

                <Modal size="lg" isOpen={!!isCreateNewClaim} toggle={() => { this.setState({ isCreateNewClaim: false }) }} >
                    <ModalHeader toggle={() => { this.setState({ isCreateNewClaim: false }) }}>Create Claim Form <br></br>
                    </ModalHeader>
                    <ModalBody className="p-3">
                        {!!isCreateNewClaim && <JobTaskConfig job={jobData} onClose={() => { this.fetchJobTasks(); this.setState({ isCreateNewClaim: false }) }} />}
                    </ModalBody>

                </Modal>

                <ConfirmAction message={this.state.confirmMessage} isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({ isOpenConfirm: false, confirmMessage: null })} />

                <ConfirmAction message={this.state.confirmMessage} isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({ isOpenConfirm: false, confirmMessage: null })} />
           
            </> 
    );
  }
 }
export default JobTaskDetail;
